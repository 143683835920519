import { useApiReportTemplates } from '@reports/composables'
import {
  Product,
  type ReportTemplate,
  type ReportTemplateFieldDefinition,
} from '@reports'
import type { Sector } from '@register'

export const TEMPLATE_CUSTOM: ReportTemplate = {
  id: 'custom',
  name: 'Unsaved custom report',
  description: 'This is a custom report that has not been saved',
  isCustomTemplate: true,
  product: Product.LEASE_REGISTER, // TODO: in the future, here must be dynamic
}

export function useTemplates() {
  const route = useRoute('division-companyId-reports')
  const query = useRouteQuery()
  const { data, isPending: isPendingTemplates } = useApiReportTemplates()
  const { allFields, isPending: isPendingFields } =
    useApiLeaseFieldGroupsByCompanyId(route.params.companyId)
  const isLoading = computed(
    () => isPendingTemplates.value || isPendingFields.value,
  )
  const templates = computed<ReportTemplate[]>(() => {
    const _templates: ReportTemplate[] = [...(data.value ?? [])]

    if (query.value.template === 'custom') {
      const fields: ReportTemplateFieldDefinition[] = (
        query.value.fieldIds ?? []
      ).map((fieldId: string) => {
        const field = allFields.value.find((f) => f.id === fieldId)
        return {
          id: field?.id ?? '',
          name: field?.name ?? 'N/A',
          description: field?.description ?? 'N/A',
        }
      })

      _templates.push({
        ...TEMPLATE_CUSTOM,
        template: {
          fields: fields,
        },
        sector: query.value.sector as Sector | undefined,
      })
    }

    return _templates
  })

  return {
    templates,
    isLoading,
  }
}
